<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Shartnoma registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group">
            <v-combobox
              v-model="getAllCorCards"
              :items="getAllCorCards"
              disabled
              item-text="provider_name"
              item-value="client"
              label="Mijoz nomi"
              outlined
            ></v-combobox>
          </div>

          <div class="form-group">
            <v-select
              :error-messages="typeOfContractErrors"
              v-model="typeOfContract"
              item-text="state"
              outlined
              item-value="abbr"
              :items="items"
              label="Shartnoma turi"
              @input="$v.typeOfContract.$touch()"
              @blur="$v.typeOfContract.$touch()"
            ></v-select>
          </div>
          <div class="form-group">
            <v-text-field
              :error-messages="contractNumberErrors"
              v-model="contractNumber"
              label="Shartnoma nomeri"
              outlined
              @input="$v.contractNumber.$touch()"
              @blur="$v.contractNumber.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group">
            <!-- <label>Shartnoma sanasi</label>
            <b-form-input
              :error-messages="contractNumberErrors"
              v-model="dateOFContract"
              id="`type-date`"
              type="date"
              @input="$v.contractNumber.$touch()"
              @blur="$v.contractNumber.$touch()"
            ></b-form-input> -->
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateOFContract"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOFContract"
                  :error-messages="dateOFContractErrors"
                  outlined
                  label="Shartnoma sanasi"
                  prepend-icon="event"
                  readonly
                  @input="$v.dateOFContract.$touch()"
                  @blur="$v.dateOFContract.$touch()"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateOFContract" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn small color="error" @click="menu = false"
                  >Bekor qilishh</v-btn
                >
                <v-btn
                  small
                  color="success"
                  @click="$refs.menu.save(dateOFContract)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </div>
          <div class="form-group">
            <!-- <label>Shartnoma tugash sanasi</label>
            <b-form-input
              v-model="endOfContract"
              id="`type-date`"
              type="date"
            ></b-form-input> -->
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="endOfContract"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :error-messages="endOfContractErrors"
                  v-model="endOfContract"
                  outlined
                  label="Shartnoma sanasi"
                  prepend-icon="event"
                  readonly
                  @input="$v.endOfContract.$touch()"
                  @blur="$v.endOfContract.$touch()"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="endOfContract" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn small color="error" @click="menu1 = false"
                  >Bekor qilish</v-btn
                >
                <v-btn
                  small
                  color="success"
                  @click="$refs.menu1.save(endOfContract)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      provider_name: '',
      typeOfContract: '',
      dateOFContract: '',
      contractNumber: '',
      endOfContract: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false
    }
  },
  validations: {
    typeOfContract: {
      required
    },
    dateOFContract: {
      required
    },
    contractNumber: {
      required
    },
    endOfContract: {
      required
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllProvidersContract')
  },
  computed: {
    typeOfContractErrors() {
      const errors = []
      if (!this.$v.typeOfContract.$dirty) return errors

      !this.$v.typeOfContract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    dateOFContractErrors() {
      const errors = []
      if (!this.$v.dateOFContract.$dirty) return errors

      !this.$v.dateOFContract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contractNumberErrors() {
      const errors = []
      if (!this.$v.contractNumber.$dirty) return errors

      !this.$v.contractNumber.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    endOfContractErrors() {
      const errors = []
      if (!this.$v.endOfContract.$dirty) return errors

      !this.$v.endOfContract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    getAllCorCards() {
      const data = this.$store.state.requests.allProvidersContract.find(
        (x) => x.id == this.$route.params.id
      )

      if (data !== undefined) {
        if (data.provider_inn == null) {
          data.provider_inn = 'kiritilmagan'
        }
        data.provider_name =
          data.provider_name + ' (Inn: ' + data.provider_inn + ')'
      }

      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Shartnoma registratsiyasi' }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        const data = {
          contract_type: this.typeOfContract,
          end_date: this.endOfContract,
          start_date: this.dateOFContract,
          contract_number: this.contractNumber,
          client: this.getAllCorCards.client,
          parent_contract: this.$route.params.id
        }
        this.$store.dispatch('createProviderContract', data)
      }
      this.typeOfContract = ''
      this.endOfContract = ''
      this.dateOFContract = ''
      this.contractNumber = ''
      this.provider_name = ''
      this.$v.$reset()
    }
  }
}
</script>
